@import "../helpers/placeholders";

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: fade-out($dark-color, 0.3);
  z-index: 1000;
  .modalThankyou {
    box-sizing: border-box;
    max-width: 560px;
    background-color: $primary-color;
    z-index: 1500;
    opacity: 1;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    color: $dark-color;
    .modal-header {
      border-bottom: 1px solid $secondary-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      h3 {
        padding-bottom: 16px;
      }
    }
    .modal-body {
      p {
        font-size: 16px;
        line-height: 1.4;
      }
      button {
        background-color: transparent;
        color: $dark-color;
        border: 2px solid $dark-color;
        &:hover {
          color: $light-color;
          background-color: $secondary-color;
          border: 2px solid $secondary-color;
        }
      }
    }
  }
}
