.cta {
    padding: 16px 18px;
    border-radius: 10px;
    box-sizing: border-box;
    border: none;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: transform 0.5s;
    }
    &.cta-primary {
      background-color: $primary-color;
      color: $dark-color;
      &:hover {
        background-color: $secondary-color;
        color: $light-color;
      }
    }
    &.cta-secondary {
      color: $light-color;
      background-color: transparent;
      border: 2px solid $light-color;
      &:hover {
        background-color: $light-color;
        color: $dark-color;
      }
    }
  }