@import "../helpers/placeholders";

.modalOverlay {
  display: flex;
  position: fixed;
  padding: 16px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: fade-out($dark-color, 0.3);
  z-index: 1000;
  .modalOrderForm {
    max-height: 100vh!important;
    overflow-y: auto;
    box-sizing: border-box;
    max-width: 360px;
    background-color: $primary-color;
    z-index: 1500;
    opacity: 1;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px!important;
    color: $dark-color;
    .modal-header {
      border-bottom: 1px solid $secondary-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      .x-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        svg {
          height: 32px;
          width: 32px;
          &:hover {
            transform: scale(1.1);
            transition: transform 0.5s;
          }
          
        }
      }
      a {
        cursor: pointer;
        padding: 8px;
        color: $light-color;
        transform: rotate(45deg);
        align-self: center;
        &:hover {
          color: $dark-color;
          transform: scale(1.1);
          transition: transform 0.5s;
          transform: rotate(45deg);
        }
      }
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .form-product {
          width: 160px;
        }
        .form-volume {
          width: 120px;
          input {
            border: 1px solid $light-color;
          }
          
        }
      }
      .form-query {
        padding: 8px 0;
        display: flex;
        flex-direction: column;
        .text-input,
        select {
          box-sizing: border-box;
          border: 0px dotted $light-color;
          border-radius: 8px;
          padding: 8px;
        }
      }
      .form-label {
        padding: 0;
      }
      .form-buttons-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 16px 0;

        .cta-form-submit {
          @extend %cta-form;
          background-color: $secondary-color;
          color: $light-color;
          box-shadow: none;
          border: none;
          margin-right: 16px;
        }
        .cta-form-close {
          @extend %cta-form;
          border: 2px solid #E52B50;
          color: $light-color;
          background-color: #E52B50;
        }
      }
    }
  }
}
