@import "../helpers/placeholders";

.modalOverlay {
  display: flex;
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: fade-out($dark-color, 0.3);
  z-index: 1000;
  padding: 16px;
  .modalProductInfo {
    box-sizing: border-box;
    max-width: 1200px;
    background-color: $primary-color;
    z-index: 1500;
    opacity: 1;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px!important;
    color: $dark-color;
    overflow-y: auto;
    margin-bottom: 128px;
    .modal-header {
      border-bottom: 1px solid $secondary-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .x-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        svg {
          height: 32px;
          width: 32px;
          &:hover {
            transform: scale(1.1);
            transition: transform 0.5s;
          }
        }
      }
    }
    .modal-body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 16px;
      .short-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        .product-img {
          width: 400px;
          height: 268px;
          border-radius: 15px;
          &:hover {
            overflow: hidden;
          }
          img {
            height: 100%;
            width: 100%;
            border-radius: 15px;
            &:hover {
              -webkit-transform: scale(1.1);
              -moz-transform: scale(1.1);
              -o-transform: scale(1.1);
              -ms-transform: scale(1.1);
              transform: scale(1.1);
              transition: transform 2s;
            }
          }
        }
        .product-desc {
          p {
            font-size: 16px;
          }
        }
      }
      .modal-table {
        flex: 2;
        justify-content: flex-end;
        box-sizing: border-box;
        margin-left: 16px;

        table {
          width: 100%;
          th,
          td {
            border: 1px solid $light-color;
            padding: 8px 16px;
            font-size: 16px;
          }
        }
      }
    }
    .modal-footer {
      display: flex;
      flex-direction: row-reverse;
      a {
        color: $light-color;
        background-color: $secondary-color;
        margin: 0;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .modalProductInfo {
    width: 360px;
    .modal-body {
      flex-direction: column !important;
      overflow: scroll!important;
      // overflow-y: scroll!important;
      .product-img {
        width: 100%!important;
        height: 214px!important;
        border-radius: 15px!important;
        &:hover {
          overflow: hidden;
        }
      }
      .product-desc {
        p {
          font-size: 12px!important;
        }
      }
      .modal-table {
        margin: 0!important;
        table {
          width: 100%;
          th,
          td {
            border: 1px solid $light-color;
            padding: 4px!important;
            font-size: 12px!important;
          }
        }
      }
    }
  }
}
