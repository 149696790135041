@import "./helpers/variables";
@import "./helpers/mixins";

@import "./layout/technical";

@import "./design/typography";
@import "./design/colors";

@import "./components/buttons";
@import "./components/modalOrderForm";
@import "./components/modalProductInfo";
@import "./components/modalThankyou";

@import "./design/background";

// Global

span {
  display: inline-block;
}

html {
  scroll-behavior: smooth;
  body {
    width: 100%;
    overflow-x: hidden;
    .container {
      box-sizing: border-box;
      max-width: 1280px;
      margin: auto;
      padding: 16px;
      display: flex;
      flex-direction: column;
      // background-color: rgba($color: #ff033e, $alpha: 0.1);
      @include screen-max (540px) {
        padding: 0;
      }
      h2.section-title {
        text-transform: uppercase;
        padding: 32px 0;
        margin: 0;
        @include screen-max(880px) {
          text-align: center;
        }
        @include screen-max (540px) {
          padding: 24px 0;
        }
        span {
          color: $secondary-color;
        }
      }
      .component {
        // border: 2px dashed grey !important;
      }
    }
    .container-order {
      padding: 32px auto;
    }
    section {
      &.filled {
        background-color: $primary-color;
        color: $dark-color;
      }
    }
    .bg-yellow {
      background-color: $primary-color;
    }
    .text-green {
      color: $secondary-color;
    }
    .border {
      border: 1px solid $light-color;
    }
    .bordered > div {
      border: 1px solid $light-color;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////// Main content

.landing {
  .navbar {
    height: 15vh;
    .component {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include screen-max (800px) {
        justify-content: space-evenly;
      }
      .logo-row {
        min-width: 280px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        @include screen-max (540px) {
          margin: 16px 0;
        }
        .logo-container {
          height: 64px;
          width: 64px;
          transform: scale(1.1);
          transition: transform 0.5s;
          img {
            height: 100%;
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
              transition: transform 0.5s;
            }
          }
        }
        .logo-descriptor {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;
          color: $light-color;
          margin-left: 8px;
          @include screen-max (656px) {
            margin-left: 8px;
          }
          @include screen-max (540px) {
            width: 232px;
          }
          h3 {
            text-transform: uppercase;
            margin: 4px;
          }
          p {
            margin: 4px;
          }
        }
      }
      .lang-selector {
        margin: 8px;
        select {
          padding: 16px;
          border-radius: 8px;
        }
      }
      .cta-navbar {
        display: flex;
        justify-content: flex-end;
        @include screen-max (800px) {
          display: none;
        }
      }
    }
  }
  .hero {
    height: 85vh;
    .hero-banner {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 160px 8px;
      @include screen-max(880px){
        flex-direction: column;
        margin: 64px 8px;
      }
      @include screen-max (540px) {
        margin: 32px 8px;
      }
      .hero-content {
        display: flex;
        flex-direction: column;
        color: $light-color;
        width: 720px;
        align-items: flex-start;
        justify-content: space-around;
        @include screen-max(880px) {
          width: 100%;
          align-items: center;
          text-align: center;
        }
        @include screen-max(800px) {
          width: 100%;
          align-items: center;
          text-align: center;
        }
        h2 {
          margin: 24px 0;
          font-weight: 500;
        }
        .cta-group {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          width: auto;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          @include screen-max (540px) {
            gap: 16px;
            margin: 32px auto;
          }
        }
      }
      .hero-features {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include screen-max-min (880px, 800px) {
          flex-direction: row;
          justify-content: center;
          margin: 32px auto;
        }
        @include screen-max (799px) {
          margin: 16px auto;
        }
        .hero-feature {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          box-sizing: border-box;
          justify-content: center;
          &:nth-child(2) {
            
            .hero-feature-text {
              p {
                @include screen-max-min(880px, 800px){
                  width: 150px!important;
                }
              }
            }
            
          }
          &:nth-child(3) {
            
            .hero-feature-text {
              p {
                @include screen-max-min(880px, 800px){
                  width: 140px!important;
                }
              }
            }
            
          }
          @include screen-max (799px) {
            margin: 12px auto;
          }
          .hero-feature-content {
            display: flex;
            flex-direction: row;
            .hero-feature-img {
              width: 64px;
              display: flex;
              align-items: center;
              @include screen-max (880px) {
                width: 48px;
              }
              img {
                width: 100%;
                height: 100%;
                &:hover {
                  transform: scale(1.1);
                  transition: transform 0.5s;
                }
              }
            }
            .hero-feature-text {
              color: $light-color;
              margin: 10px 0;
              display: flex;
              align-items: center;
              p {
                margin: auto 16px;
                width: 260px;
                @include screen-max-min (880px, 800px){
                  width: 240px;
                }
                @include screen-max (540px) {
                  width: 190px;
                }
              }
            }
          }
        }
      }
    }
  }
  .products {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex-shrink: 1;
    width: 100%;
    .product {
      box-sizing: border-box;
      max-width: 100%;
      text-decoration: none;
      text-align: center;
      padding: 16px;
      .product-img {
        width: 300px;
        height: 200px;
        margin: auto;
        height: calc(300px * 2 / 3);
        border-radius: 16px;
        cursor: pointer;
        &:hover {
          overflow: hidden;
        }
        img {
          height: 100%;
          width: 100%;
          border-radius: 16px;
          &:hover {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            transition: transform 2s;
          }
        }
      }
      .product-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .product-cta {
          margin-top: 16px;
          color: black;
          background-color: transparent;
          padding: 8px 16px;
          border: 1px solid $dark-color;
          border-radius: 8px;
          text-decoration: none;
          &:hover {
            color: $light-color;
            background-color: $secondary-color;
            cursor: pointer;
            border: 1px solid $secondary-color;
            transform: scale(1.1);
            transition: transform 0.5s;
          }
        }
      }
    }
  }
  .advantages-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
      flex: 1;
    }
    .advantage {
      box-sizing: border-box;
      min-width: 300px;
      padding: 32px;
      .img-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          padding: 24px;
        }
      }
      h3 {
        color: $light-color;
        text-align: center;
        margin-bottom: 8px;
      }
      p {
        color: $light-color;
        text-align: start;
      }
    }
  }
  .company-info {
    margin: 16px;
    p {
      line-height: 1.4;
    }
  }
  footer {
    .component {
      margin: 0;
    }
    .container {
      display: flex;
      flex-direction: column;
      .footer-grid {
        margin: 32px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        @include screen-max(880px) {
          grid-template-columns: repeat(2, 1fr);
          margin: 16px;
        }
        @include screen-max(670px) {
          grid-template-columns: repeat(1, 1fr);
          margin: 0;
        }
        .descriptor {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .logo-row {
            width: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            @include screen-max(880px){
              justify-content: space-evenly;
            }
            .logo-container {
              width: 64px;
              transform: scale(1.1);
              transition: transform 0.5s;
              display: flex;
              img {
                margin: auto;
                width: 100%;
                cursor: pointer;
                &:hover {
                  transform: scale(1.1);
                  transition: transform 0.5s;
                }
              }
            }
            .logo-descriptor {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
              color: $light-color;
              width: 240px;
              margin: 0 8px;
              h3 {
                text-transform: uppercase;
                margin: 4px;
              }
              p {
                margin: 4px;
              }
            }
          }
          button {
            margin: 16px auto;
          }
        }

        & > div {
          padding: 16px;
          & > ul {
            margin: auto;
          }
        }
        .footer-logo {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
          a {
            align-self: center;
          }
          
        }
        .footer-links {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          ul {
            padding: 0;
            li {
              list-style: none;
              padding: 8px 0;
              a {
                color: $light-color;
              }
            }
          }
        }
        .newsletter {
          flex: 1;
          padding: 16px;
          box-sizing: border-box;
          .newsletter-title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            h3 {
              text-align: center;
              padding: 8px 16px;
              background-color: transparent;
              color: $light-color;
              border: 2px solid $light-color;
              border-bottom: 0;
              border-radius: 8px 8px 0 0;
            }
          }
          .newsletter-box {
            display: flex;
            flex-direction: column;
            display: flex;
            flex-direction: column;
            background-color: transparent;
            border: 2px solid $light-color;
            padding: 8px;
            border-radius: 8px;
            .newsletter-text {
              padding: 16px 24px;
              background-color: $light-color;
              border: none;
              border-bottom: 1px solid $dark-color;
              border-radius: 8px;
              margin: 8px 0;
            }
            .newsletter-btn {
              padding: 16px;
              background-color: $light-color;
              color: $dark-color;
              border: none;
              border-radius: 8px;
              margin: 8px 0;
              &:hover {
                cursor: pointer;
                border: 1px outset $light-color;
                box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                transform: translateY(-0.25em);
                transition: transform 0.5s;
              }
            }
          }
        }
        .contact-box {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          @include screen-max (670px) {
            margin: 0 20% ;
          }
        }
      }
      .copyright {
        text-align: center;
        padding: 8px 0;
      }
    }
  }
}

